import { capitalize } from 'lodash';
import { SkiDealDefinition, SkiDealsCategory } from '../types';
import { countryNameToSlug } from '../../mega-menu/utils';
import { Resort } from '@weski-monorepo/types';

const FEATURED_SKI_DEALS_RESORTS = [1, 3, 11, 13, 12, 5, 54, 60, 62, 26, 31, 10];

function getSkiDealFromSkiSite(resorts: Resort[], resortId: string): SkiDealDefinition | null {
  const resortIdInteger = parseInt(resortId.replace('resort-', ''));
  const resort = resorts.find((resort) => resort.id === resortId);
  if (!resort) return null;

  const countryName = resort.countryName;
  return {
    cardLabel: resort.name,
    megaMenuLabel: `${capitalize(resort.name)} ski deals`,
    queryOverrides: {
      destinations: [resortIdInteger],
    },
    resortId: resortId.toString(),
    cardSubtitle: capitalize(countryName),
    cardImageSrc: resort.images.desktop.thumbnail,
    bannerImageSrcMobile: resort.images.mobile.cover,
    bannerImageSrcDesktop: resort.images.desktop.cover,
    hideFromSkiDealsPage: !FEATURED_SKI_DEALS_RESORTS.includes(parseInt(resortId.replace('resort-', ''))),
    slug: `${countryNameToSlug(countryName)}-ski-deals/ski-resort/${resort.slug}`,
    popularityRating: resort?.popularityRating,
    hideFromMegaMenu: !FEATURED_SKI_DEALS_RESORTS.includes(resortIdInteger),
  };
}

export function getResortSkiDeals(resorts: Resort[]): SkiDealsCategory {
  const skiDealsResortIds = resorts.filter((resort) => resort.enabled && !resort.adminOnly).map((r) => r.id);

  return {
    label: 'Deals by ski resort',
    megaMenuLabel: 'Most popular ski resorts',
    type: 'smallThumbnail',
    gridColumns: 4,
    deals: skiDealsResortIds
      .map((resortId) => getSkiDealFromSkiSite(resorts, resortId))
      .filter(Boolean) as SkiDealDefinition[],
  };
}
