import { getResortSkiDeals } from './ski-deal-categories/resort-ski-deals';
import { getHolidayTypesSkiDeals } from './ski-deal-categories/holiday-types-ski-deals';
import { getSpecialDatesSkiDeals } from './ski-deal-categories/special-dates-ski-deals';
import { getCountrySkiDeals } from './ski-deal-categories/country-ski-deals';
import { getMonthSkiDeals } from './ski-deal-categories/month-ski-deals';
import { getStayTypeSkiDeals } from './ski-deal-categories/by-stay-type-ski-deals';
import { Resort } from '@weski-monorepo/types';
import { hasFeature } from '../feature-flags';
import { QueryWithoutDepartingFrom } from './types';
import { useOrigin } from '../../services/origins/useOrigin';

const SEASON = 2025; // TODO: not const

export function getDefaultDealQuery(): QueryWithoutDepartingFrom {
  return {
    components: {
      accommodation: true,
      flight: true,
      skiPass: false,
      transfer: true,
    },
    dates: [
      {
        duration: 7,
        type: 'flexible',
        periods: [
          {
            season: SEASON,
          },
        ],
      },
    ],
    rooms: [
      {
        adults: 2,
        children: 0,
        childrenAges: [],
      },
    ],
    destinations: [{ type: 'flexible', id: 0 }],
  };
}

export const skiDealCategories = (resorts: Resort[]) => ({
  byResort: getResortSkiDeals(resorts),
  byHolidayType: getHolidayTypesSkiDeals(),
  bySpecialDates: getSpecialDatesSkiDeals(),
  byCountry: getCountrySkiDeals(resorts),
  byMonth: getMonthSkiDeals(resorts),
  byStayType: getStayTypeSkiDeals(),
});

export const getSkiDeals = (resorts: Resort[]) => {
  const categories = skiDealCategories(resorts);
  return [
    categories.byCountry,
    ...(hasFeature('searchByResortPreference') ? [categories.byHolidayType] : []),
    categories.bySpecialDates,
    categories.byResort,
    categories.byMonth,
    categories.byStayType,
  ];
};

export function useSkiDealsEnabled() {
  const origin = useOrigin();
  return origin?.id !== 'il';
}
