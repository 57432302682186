import { SearchRequest } from '@weski-monorepo/types';
import { log } from '../../../infra/log';

let isSearching = false;
let startSearchTime: number;
let interval: number;
let searchComponents: string;
let searchRequest: SearchRequest;
let gotResults = false;

const elapsedTimeMs = () => Date.now() - startSearchTime;
const monitor = (metricName: string, value?: object) => {
  log.info(`search-${metricName}`, {
    searchComponents,
    searchParams: searchRequest,
    ssr: true,
    ...value,
  });
};

export const onSearchStart = (params: SearchRequest) => {
  if (isSearching) {
    return;
  }
  gotResults = false;
  isSearching = true;
  searchComponents = Object.keys(params.components)
    .filter((key) => params.components[key as keyof typeof params.components])
    .join('-');
  searchRequest = params;
  monitor('start');
  startSearchTime = Date.now();
  searchTimerInterval();
};

export const onPollDone = () => {
  if (!isSearching) {
    return;
  }
  monitor('done', { elapsedTimeMs: elapsedTimeMs() });
  clearInterval(interval);
  isSearching = false;
  gotResults = false;
};

export const onResults = () => {
  if (isSearching && !gotResults) {
    gotResults = true;
    monitor('first-result', { elapsedTimeMs: elapsedTimeMs() });
  }
};

const searchTimerInterval = () => {
  const steps = [5, 10, 20, 30, 40, 50, 60];
  let secondsPassed = 0;
  interval = setInterval(() => {
    if (isSearching) {
      secondsPassed++;
      for (const step of steps.reverse()) {
        if (secondsPassed === step + 1) {
          monitor(`taking-more-than-${step}-seconds`);
          return;
        }
      }
    }
  }, 1000) as any as number;
};
