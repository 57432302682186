type FeatureFlag = () => boolean;
interface FeatureFlags {
  [key: string]: FeatureFlag;
}
const features = {
  /**
   * 'help me choose' / 'newbie flow' on destination search
   */
  searchByResortPreference: () => false,
} satisfies FeatureFlags;
type FeatureName = keyof typeof features;

export function hasFeature(feature: FeatureName) {
  return features[feature]();
}
