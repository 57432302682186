import { type cookies } from 'next/headers';
import { SearchRequest } from '@weski-monorepo/types';
import Cookies from 'js-cookie';
import {
  ActiveComponentFilters,
  SearchFormFields,
  searchRequestToSearchFormValues,
} from '@weski-monorepo/react-components';

type ServerCookies = ReturnType<typeof cookies>;
const COOKIE_NAME = 'recentSearchRequest';
const NEW_COOKIE_NAME = 'searchFormRecentSearch';

interface SearchRequestCookie {
  search: SearchRequest;
  filters: ActiveComponentFilters[];
}

/**
 * Note: getRecentSearch is called from the server side rendering, so it uses Next to handle cookies.
 * On the other hand, setRecentSearch is called from the client so it uses js-cookie
 */

// TODO: keep only NEW COOKIE flow when old search from is dead

export function getRecentSearch(cookieStore: ServerCookies): SearchFormFields | undefined {
  const newRecentSearchCookie = cookieStore.get(NEW_COOKIE_NAME)?.value;
  const recentSearchRequest = cookieStore.get(COOKIE_NAME)?.value;

  try {
    if (recentSearchRequest) {
      const recentSearchRequestParsed: SearchRequestCookie = JSON.parse(recentSearchRequest);
      return searchRequestToSearchFormValues(recentSearchRequestParsed.search, recentSearchRequestParsed.filters);
    }

    if (newRecentSearchCookie) {
      return stringSearchToSearchFormFields(newRecentSearchCookie);
    }
  } catch (error) {
    console.error('error on searchRequestToSearchFormValues', { error });
    return undefined;
  }
}

export function setRecentSearch(
  searchRequest: SearchRequest,
  filters: ActiveComponentFilters[],
  searchFormFields: SearchFormFields
) {
  Cookies.set(COOKIE_NAME, JSON.stringify({ search: searchRequest, filters }), {
    expires: 365,
  });
  Cookies.set(NEW_COOKIE_NAME, JSON.stringify(searchFormFields), {
    expires: 365,
  });
}

const stringSearchToSearchFormFields = (stringSearchFields: string): SearchFormFields => {
  const parsedNewRecentSearch = JSON.parse(stringSearchFields) as SearchFormFields;

  const fromDate = parsedNewRecentSearch.dates.dateRangeSearch.range?.from;
  if (fromDate) {
    parsedNewRecentSearch.dates.dateRangeSearch.range!.from = new Date(fromDate);
  }

  const toDate = parsedNewRecentSearch.dates.dateRangeSearch.range?.to;
  if (toDate) {
    parsedNewRecentSearch.dates.dateRangeSearch.range!.to = new Date(toDate);
  }

  return parsedNewRecentSearch;
};
