import { create } from 'zustand';

/**
 * This store exists only for the annoying ability to focus on the search bar via the "find your match" link in the homepage
 */
export const useSearchFocusStore = create<{
  searchBarFocused: boolean;
  setSearchBarFocused: (focused: boolean) => void;
}>((set) => ({
  searchBarFocused: false,
  setSearchBarFocused: (focused: boolean) => {
    set(() => ({ searchBarFocused: focused }));
  },
}));
