import { camelCase, capitalize, upperFirst } from 'lodash';
import { SkiDealDefinition, SkiDealsCategory } from '../types';
import { IconName } from '@weski-monorepo/react-components';
import { Resort } from '@weski-monorepo/types';

const SKI_DEALS_COUNTRIES = ['FR', 'CH', 'AD', 'IT', 'AT', 'BG', 'US', 'CA', 'GE'];

function getSkiDealFromCountry(resorts: Resort[], countryCode: string): SkiDealDefinition | null {
  const resort = resorts.find((r) => r.countryCode.toLowerCase() === countryCode.toLowerCase());
  if (!resort) return null;

  const countryName = resort.countryName;
  return {
    cardLabel: `${capitalize(countryName)} ski deals`,
    icon: upperFirst(camelCase(countryName.toLowerCase() === 'united states' ? 'usa' : countryName)) as IconName,
    megaMenuLabel: `${capitalize(countryName)} ski deals`,
    bannerImageSrcDesktop: resort.images.desktop.cover,
    bannerImageSrcMobile: resort.images.mobile.cover,
    queryOverrides: {
      destinations: [
        {
          type: 'country',
          id: countryCode.toUpperCase(),
        },
      ],
    },
    slug: `${countryName.toLowerCase().replace(/ /g, '-')}-ski-deals`,
  };
}

export function getCountrySkiDeals(resorts: Resort[]): SkiDealsCategory {
  return {
    label: 'Deals by country',
    megaMenuLabel: 'By country',
    gridColumns: 3,
    gridColumnsMobile: 1,
    showMoreLimit: 6,
    showMoreLimitMobile: 4,
    type: 'horizontalIcon',
    deals: SKI_DEALS_COUNTRIES.map((country) => getSkiDealFromCountry(resorts, country)).filter(
      Boolean
    ) as SkiDealDefinition[],
  };
}
