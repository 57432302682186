import { SearchRequest } from '@weski-monorepo/types';
import { weskiApi } from '../../api';

export async function search(searchRequest: SearchRequest) {
  const res = await weskiApi.apiClient.post<{
    searchId: string;
  }>('/search', searchRequest);
  const { searchId } = res.data;
  return searchId;
}
