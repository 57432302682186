'use client';

import {
  SearchForm,
  SearchFormLayout,
  ActiveComponentFilters,
  SearchFormFields,
} from '@weski-monorepo/react-components';
import { SearchRequest } from '@weski-monorepo/types';
import { FC, useCallback, useRef } from 'react';
import { search } from './search';
import { setRecentSearch } from './recent-search-cookie';
import { useAnalytics } from '../../services/analytics/useAnalytics';
import { useIsAdmin } from '../../auth/hooks/useIsAdmin';
import { hasFeature } from '../feature-flags';
import { useRouter } from 'next/navigation';
import { useSearchFormInitData } from './use-search-form-init-data';
import { useSearchFocusStore } from '../../app/homepage/search-focus-store';
import * as searchClientPerformanceMonitoring from '../../app/package-search/todo/search-performance-monitoring';

interface SearchFormClientProps {
  showComponentsField?: boolean;
  showLabel?: boolean;
  scrollToTopOnClose?: boolean;
  layout?: 'horizontal' | 'vertical';
  isFirstTimer?: boolean;
  onSearch?: () => void;
  ctaVariant?: 'outlined' | 'main';
}

const SearchFormClient: FC<SearchFormClientProps> = ({
  layout = 'horizontal',
  showComponentsField = true,
  showLabel = true,
  scrollToTopOnClose = false,
  isFirstTimer = false,
  ctaVariant,
  onSearch = () => {},
}) => {
  const isAdmin = useIsAdmin();
  const router = useRouter();
  const searchFormData = useSearchFormInitData();
  const { sendAnalytics } = useAnalytics();
  const { searchBarFocused, setSearchBarFocused } = useSearchFocusStore();

  // this is to prevent double submission
  const isSubmittingRef = useRef(false);

  const submitSearch = useCallback(
    async (searchRequest: SearchRequest, filters: ActiveComponentFilters[], searchFormFields: SearchFormFields) => {
      if (!isSubmittingRef.current) {
        isSubmittingRef.current = true;
        try {
          searchClientPerformanceMonitoring.onSearchStart(searchRequest);
          const searchId = await search(searchRequest);
          isSubmittingRef.current = false;
          setRecentSearch(searchRequest, filters, searchFormFields);
          let url = `/package-search?search_id=${searchId}&ssr=true`;
          if (filters.length > 0) {
            url += `&filters=${JSON.stringify(filters)}`;
          }
          setSearchBarFocused(false);
          router.push(url);
        } catch (error) {
          isSubmittingRef.current = false;
        }
      }

      (document.activeElement as HTMLDivElement)?.blur?.();
      onSearch();

      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [onSearch, router, setSearchBarFocused]
  );

  return (
    <SearchForm
      data={searchFormData!}
      layout={layout === 'horizontal' ? SearchFormLayout.V1 : SearchFormLayout.V2}
      onSubmit={submitSearch}
      onAnalytics={sendAnalytics}
      options={{
        showIcons: true,
        showComponentsField,
        showLabel,
        isAdmin,
        scrollToTopOnClose,
        isFirstTimer,
        canSearchByResortPreference: hasFeature('searchByResortPreference'),
      }}
      ctaVariant={searchBarFocused ? 'main' : ctaVariant}
    />
  );
};

export default SearchFormClient;
