import { Domain } from './utils/get-domain';

export const CDN_IMAGE_URLS = {
  weskiLogo: 'https://res.cloudinary.com/ht4mr7djk/image/upload/v1727341362/weski_logo_horiznotal.svg',
  weskiLogoWhite: 'https://res.cloudinary.com/ht4mr7djk/image/upload/v1731492949/weski_logo_horiznotal_white.svg',
  // defaultDesktopHeroBackgroundImage:
  //   'https://res.cloudinary.com/ht4mr7djk/image/upload/v1704208645/App%20Assets/Homepage/HP_tailored_ski_trips.jpg',
  defaultDesktopHeroBackgroundImage:
    'https://res.cloudinary.com/ht4mr7djk/image/upload/v1731410978/App%20Assets/Homepage/Campaigns/Black%20Friday%202024-25/Hero_image_-_buy_memories.webp',
  defaultMobileHeroBackgroundImage:
    'https://res.cloudinary.com/ht4mr7djk/image/upload/v1704810068/App%20Assets/Homepage/Static_hp.jpg',
  worldSkiAwardBadge:
    'https://res.cloudinary.com/ht4mr7djk/image/upload/v1663586828/App%20Assets/Homepage/NewWorldSkiBadge.webp',
  weskiWithOnTheSnowLogo:
    'https://res.cloudinary.com/ht4mr7djk/image/upload/v1607006951/App%20Assets/Partnership%20projects/WeSki_and_Onthesnow_3X.png',
  weskiWithJ2SkiLogo:
    'https://res.cloudinary.com/ht4mr7djk/image/upload/v1726156097/App%20Assets/Partnership%20projects/J2SKI/WeSki_with_J2ski.png',
};

export const LINKS = {
  instagram: 'https://www.instagram.com/weski_travel/',
  facebook: 'https://www.facebook.com/WeSkiOfficialPage',
  pagePrefix: {
    skiResorts: '/ski-resorts',
    skiHolidays: '/ski-holidays',
    skiAreas: '/ski-resorts/ski-areas',
    skiDeals: '/ski-deals',
    skiAccommodations: '/ski-holidays/accommodation-types',
    skiGuides: '/guides',
    savedTrips: '/trips',
    manageBookings: '/bookings',
    helpCenter: '/help',
  },
  helpCenter: '/help',
  about: '/about',
  careers: 'https://careers.weski.com',
  trustpilot: 'https://www.trustpilot.com/review/www.weski.com',
  contactEmail: 'mailto:contact@weski.com',
  weskiPromise: (domain: Domain) => `${termBaseUrlByDomain[domain]}/our-promise`,
  termsOfService: (domain: Domain) => `${termBaseUrlByDomain[domain]}/terms-of-service`,
  termsOfSale: (domain: Domain) => `${termBaseUrlByDomain[domain]}/terms-of-sale`,
  privacy: (domain: Domain) => `${termBaseUrlByDomain[domain]}/privacy-policy`,
  cancellationPolicy: (domain: Domain) => `${termBaseUrlByDomain[domain]}/cancellation-policy`,
  lowestPriceGuarantee: (domain: Domain) => `${termBaseUrlByDomain[domain]}/our-promise#lowest-price-guarantee`,
  bookWithDeposit: (domain: Domain) => `${termBaseUrlByDomain[domain]}/our-promise#booking-a-trip-with-a-deposit`,
  fullCashRefund: (domain: Domain) => `${termBaseUrlByDomain[domain]}/our-promise#full-cash-refund-within-14-days`,
  support247: (domain: Domain) =>
    `${termBaseUrlByDomain[domain]}/our-promise#247-support-during-your-trip-onground-and-online`,
  submitRequest: 'https://formcrafts.com/a/pnvvpyb',
};

const termBaseUrlByDomain: Record<Domain, string> = {
  'co.uk': 'https://terms.weski.com/uk/docs',
  com: 'https://terms.weski.com/uk/docs',
  ie: 'https://terms.weski.com/europe/docs',
};
