import { SearchFormExtendedInitData } from '@weski-monorepo/react-components';
import { OriginAirport, SearchInitData } from '@weski-monorepo/types';
import { useQuery } from '@tanstack/react-query';
import { weskiApi } from '../../api';

export function useSearchFormInitData() {
  const { data } = useQuery<SearchFormExtendedInitData>({
    queryKey: ['searchFormData'],
    queryFn: async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const requestsConfig = {
        params: { set_origin: urlParams.get('set_origin') },
      };

      const [initData, nearestAirport] = await Promise.all([
        weskiApi.apiClient.get<{ data: SearchInitData }>(`/search/init`, requestsConfig),
        weskiApi.apiClient.get<{ data: { nearestAirport: OriginAirport | null } }>(
          `/search/nearest-airport`,
          requestsConfig
        ),
      ]);

      return {
        ...initData.data.data,
        nearestAirport: nearestAirport.data.data.nearestAirport,
      };
    },
    staleTime: Infinity,
  });

  return data;
}
