import { useCallback } from 'react';
import { useOrigin } from './origins/useOrigin';

interface Options {
  withSign: boolean;
  withSymbol: boolean;
  withThousandsSeparator: boolean;
  showPaymentCurrency: boolean;
}

const defaultOptions: Options = {
  withSign: false,
  withSymbol: true,
  withThousandsSeparator: true,
  showPaymentCurrency: false,
};

const getSign = (num: number) => {
  return num > 0 ? '+' : num < 0 ? '-' : '';
};

const numberWithCommas = (number: string) => number.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export function useDisplayWithCurrency() {
  const origin = useOrigin();
  return useCallback(
    (amount: number | undefined, options: Partial<Options> = {}) => {
      if (!amount) {
        return '';
      }
      const optionsWithDefaults = {
        ...defaultOptions,
        ...options,
      };
      const { showPaymentCurrency, withSign, withThousandsSeparator, withSymbol } = optionsWithDefaults;
      if (!origin) return `${amount}`;
      const { symbol } = showPaymentCurrency ? origin.payment_currency : origin.currency;

      if (typeof amount !== 'number') {
        amount = parseFloat(amount);
      }
      amount = Math.round(amount);
      const amountString = Math.abs(amount).toFixed(0);

      return `${withSign ? getSign(amount) : ''}${withSymbol ? symbol : ''}${
        withThousandsSeparator ? numberWithCommas(amountString) : amountString
      }`;
    },
    [origin]
  );
}
