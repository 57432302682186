import { DateTime } from 'luxon';
import { SkiDealDefinition, SkiDealsCategory } from '../types';
import _, { capitalize } from 'lodash';
import { IconName } from '@weski-monorepo/react-components';
import { Resort } from '@weski-monorepo/types';

const SEASON = 2025; // TODO: not const
const SKI_DEALS_MONTHS = [12, 1, 2, 3, 4];

function getRandomSkiResortImage(resorts: Resort[]) {
  const resortWithGoodImages = [1, 3, 11, 13, 12, 5, 54, 60, 62, 26, 31, 10];
  const randomResortId = _.sample(resortWithGoodImages)!;
  const resort = resorts.find((resort) => resort.id === `resort-${randomResortId}`);
  return resort?.images;
}

function getSkiDealFromMonth(resorts: Resort[], month: number): SkiDealDefinition {
  const monthDateTime = DateTime.fromObject({ month });
  const monthName = monthDateTime.toFormat('MMMM');
  const monthShortName = monthDateTime.toFormat('MMM');
  const imagesOfRandomResort = getRandomSkiResortImage(resorts);
  const year = month > 7 ? SEASON - 1 : SEASON;

  return {
    cardLabel: capitalize(monthName),
    megaMenuLabel: `${capitalize(monthName)} ${year} ski deals`,
    icon: `Calendar${monthShortName}` as IconName,
    cardSubtitle: year.toString(),
    cardImageSrc: imagesOfRandomResort?.mobile.thumbnail,
    bannerImageSrcMobile: imagesOfRandomResort?.mobile.cover,
    bannerImageSrcDesktop: imagesOfRandomResort?.desktop.cover,
    slug: `${monthName.toLowerCase()}-${year}-ski-deals`,
    queryOverrides: {
      dates: [
        {
          duration: 7,
          type: 'flexible',
          periods: [
            {
              year,
              month,
            },
          ],
        },
      ],
    },
  };
}

export function getMonthSkiDeals(resorts: Resort[]): SkiDealsCategory {
  return {
    label: 'Winter 2024/25 deals',
    megaMenuLabel: 'Winter 2024/25 ski deals',
    gridColumns: 5,
    gridColumnsMobile: 3,
    type: 'verticalIcon',
    deals: SKI_DEALS_MONTHS.map((month) => getSkiDealFromMonth(resorts, month)),
  };
}
