import { Destination, GuideCategory, Origin, Resort } from '@weski-monorepo/types';
import { ColumnList, MegaMenuData } from './types';
import { Dictionary, groupBy } from 'lodash';
import { IconName } from '@weski-monorepo/react-components';
import { getSkiDeals } from '../ski-deals/ski-deals-definitions';
import { SkiDealsCategory } from '../ski-deals/types';
import { LINKS } from '../../constants';

const countryIconName = (countryName: string) => {
  return countryName === 'Bosnia and Herzegovina'
    ? 'BosniaSquare'
    : (`${countryName.replace(' ', '')}Square` as IconName);
};

export const countryNameToSlug = (countryName: string): string => {
  return countryName.toLowerCase().split(' ').join('-');
};

const countryDestinationsMenuOptions = (destinationsByCountry: Dictionary<Destination[]>) => {
  return Object.entries(destinationsByCountry).map(([countryName, destinations]) => ({
    name: countryName,
    iconName: countryIconName(countryName),
    pageUrl: `/ski-resorts/${countryNameToSlug(countryName)}`,
    subOptions: destinations.map((destination) => ({
      name: destination.name,
      pageUrl: `/ski-resorts/${countryNameToSlug(countryName)}/${destination.slug}`,
    })),
  }));
};

const areasDestinationsMenuOptions = (areasByCountry: Dictionary<Destination[]>) => {
  return Object.entries(areasByCountry).map(([countryName, destinations]) => ({
    name: countryName,
    iconName: countryIconName(countryName),
    subOptions: destinations.map((destination) => ({
      name: destination.name,
      pageUrl: `/ski-resorts/ski-areas/${destination.slug}`,
    })),
  }));
};

const skiHolidayTypes: Record<string, string> = {
  Beginners: 'beginner-ski-holidays',
  Families: 'family-ski-holidays',
  Groups: 'group-ski-holidays',
  Snowboarders: 'snowboarding-holidays',
  Luxury: 'luxury-ski-holidays',
};

const desiredSkiDealCategoryOrder = [
  'Most popular ski resorts',
  'By country',
  'By holiday type',
  'By date',
  'Winter 2024/25 ski deals',
  'By accommodation type',
];

const sortSkiDealCategoriesByDesiredOrder = (
  a: SkiDealsCategory,
  b: SkiDealsCategory,
  desiredOrder: SkiDealsCategory['megaMenuLabel'][]
) => {
  const indexA = desiredOrder.indexOf(a.megaMenuLabel);
  const indexB = desiredOrder.indexOf(b.megaMenuLabel);

  if (indexA === -1) return 1;
  if (indexB === -1) return -1;

  return indexA - indexB;
};

export const transformDestinationsToMegaMenuData = (
  skiResorts: Resort[],
  skiAreas: Destination[],
  countries: {
    name: string;
    popularityRating: number;
  }[],
  guideCategories: GuideCategory[],
  origin: Origin,
  skiDealsEnabled: boolean
): MegaMenuData => {
  const resortsByCountry = groupBy(skiResorts, 'countryName');
  const resortsMenuOption = countryDestinationsMenuOptions(resortsByCountry);
  const areasByCountry = groupBy(skiAreas, 'countryName');
  const areasMenuOption = areasDestinationsMenuOptions(areasByCountry);

  const skiHolidaysMenuOption = [
    {
      header: { title: 'By destination' },
      items: countries.map(({ name }) => ({
        title: name,
        href: `${LINKS.pagePrefix.skiHolidays}/${countryNameToSlug(name)}`,
      })),
    },
    {
      header: { title: 'By type' },
      items: Object.entries(skiHolidayTypes).map(([holidayType, slug]) => ({
        title: holidayType,
        href: `${LINKS.pagePrefix.skiHolidays}/${slug}`,
      })),
    },
  ];

  const skiAccommodationsMenuOption = countries
    .map(({ name }) => {
      if (!['France', 'Austria', 'Bulgaria', 'Italy', 'Andorra', 'Switzerland', 'Georgia'].includes(name)) return null;

      return {
        header: { title: name, iconName: countryIconName(name) },
        items: [
          {
            title: 'Ski hotels',
            href: `${LINKS.pagePrefix.skiAccommodations}/ski-hotels/${countryNameToSlug(name)}`,
          },
          {
            title: 'Ski apartments',
            href: `${LINKS.pagePrefix.skiAccommodations}/ski-apartments/${countryNameToSlug(name)}`,
          },
          ...(!['Bulgaria', 'Georgia'].includes(name) // TODO: get the list of accommodation menu options from weski-api
            ? [
                {
                  title: 'Ski chalets',
                  href: `${LINKS.pagePrefix.skiAccommodations}/ski-chalets/${countryNameToSlug(name)}`,
                },
              ]
            : []),
        ],
      };
    })
    .filter(Boolean) as ColumnList[];

  const skiDealCategories = skiDealsEnabled ? getSkiDeals(skiResorts) : [];
  const skiDealsMenuOption = skiDealCategories
    .sort((a, b) => sortSkiDealCategoriesByDesiredOrder(a, b, desiredSkiDealCategoryOrder))
    .map((dealCategory) => ({
      header: { title: dealCategory.megaMenuLabel },
      items: dealCategory.deals
        .filter((deal) => !deal.hideFromMegaMenu)
        .map((deal) => ({
          title: deal.megaMenuLabel,
          href: `${LINKS.pagePrefix.skiDeals}/${deal.slug}`,
        })),
    }));

  const skiGuidesMenuOption =
    guideCategories.length > 0
      ? [
          {
            items: guideCategories.map((category) => ({
              title: category.title,
              href: `${LINKS.pagePrefix.skiGuides}${category.path}`,
            })),
          },
        ]
      : [];

  return {
    skiResorts: resortsMenuOption,
    skiAreas: areasMenuOption,
    skiHolidays: skiHolidaysMenuOption,
    skiAccommodations: skiAccommodationsMenuOption,
    skiGuides: skiGuidesMenuOption,
    skiDeals: skiDealsMenuOption,
    origin,
  };
};
